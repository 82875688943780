.msnt-header-banner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 10s linear 1s;
    transition: -webkit-transform 10s linear 1s;
    transition: transform 10s linear 1s;
    transition: transform 10s linear 1s, -webkit-transform 10s linear 1s;
}.msnt-header-banner.loaded {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}.msnt-header-banner:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 1;
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear;
}.msnt-header-banner.loaded:after {
    opacity: 0;
}.msnt-header-banner.blured:after {
    opacity: 1;
}.msnt-header-news.headerBanner {
    padding: 0 20px;
    padding-top: 86px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.msnt-header-news:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .55);
    z-index: 1;
}.msnt-header-news .content {
    position: relative;
    z-index: 2 !important;
}.msnt-header-news .msnt-header-news_title {
    max-width: 800px;
    display: block;
    margin: 30px auto;
    text-align: center;
}.msnt-header-news .msnt-header-news_more {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    display: inline-block;
}.msnt-header-news .msnt-slide_badge-icon {
    border: 1px solid #fff;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}.msnt-header-news .msnt-header-news_more:hover {
    color: var(--msnt-brand-color);
}.msnt-header-news .msnt-header-news_more:hover .msnt-slide_badge-icon {
    border-color: var(--msnt-brand-color);
    color: var(--msnt-brand-color);
}.msnt-header-news_overlay {
    display: none;
}@media screen and (max-width: 767px) {
    .msnt-header-news.headerBanner {
        height: 118vw;
        padding-top: 78px;
    }

    .msnt-header-news_overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        z-index: 3;
    }
}
/*# sourceMappingURL=image-changer.css.map */