/*!rtl:begin:ignore*/@media only screen {
    .ms-flag-global,
    .ms-flag-be,
    .ms-flag-pt,
    .ms-flag-se,
    .ms-flag-sk,
    .ms-flag-ke,
    .ms-flag-jo,
    .ms-flag-om,
    .ms-flag-mr,
    .ms-flag-bn,
    .ms-flag-al,
    .ms-flag-bf,
    .ms-flag-bz,
    .ms-flag-dj,
    .ms-flag-fx,
    .ms-flag-gq,
    .ms-flag-iq,
    .ms-flag-la,
    .ms-flag-mm,
    .ms-flag-na,
    .ms-flag-pg,
    .ms-flag-sb,
    .ms-flag-sy,
    .ms-flag-to,
    .ms-flag-vu,
    .ms-flag-251,
    .ms-flag-261,
    .ms-flag-271,
    .ms-flag-281,
    .ms-flag-291 {
        background-position-x: 0;
    }
    .ms-flag-us,
    .ms-flag-es_us,
    .ms-flag-nl,
    .ms-flag-mx,
    .ms-flag-lat,
    .ms-flag-nz,
    .ms-flag-lv,
    .ms-flag-cy,
    .ms-flag-li,
    .ms-flag-mk,
    .ms-flag-eg,
    .ms-flag-pf,
    .ms-flag-am,
    .ms-flag-bg,
    .ms-flag-cc,
    .ms-flag-dm,
    .ms-flag-ga,
    .ms-flag-gs,
    .ms-flag-is,
    .ms-flag-lc,
    .ms-flag-mn,
    .ms-flag-nc,
    .ms-flag-pk,
    .ms-flag-sc,
    .ms-flag-sz,
    .ms-flag-tp,
    .ms-flag-wf,
    .ms-flag-252,
    .ms-flag-262,
    .ms-flag-272,
    .ms-flag-282,
    .ms-flag-292 {
        background-position-x: -100%;
    }
    .ms-flag-it,
    .ms-flag-ch,
    .ms-flag-ch_de,
    .ms-flag-ch_it,
    .ms-flag-ch_fr,
    .ms-flag-ua,
    .ms-flag-id,
    .ms-flag-gr,
    .ms-flag-lb,
    .ms-flag-hr,
    .ms-flag-lt,
    .ms-flag-ds,
    .ms-flag-by,
    .ms-flag-an,
    .ms-flag-bi,
    .ms-flag-cg,
    .ms-flag-do,
    .ms-flag-gd,
    .ms-flag-gt,
    .ms-flag-jm,
    .ms-flag-lk,
    .ms-flag-mp,
    .ms-flag-ne,
    .ms-flag-pm,
    .ms-flag-sd,
    .ms-flag-tc,
    .ms-flag-tv,
    .ms-flag-ws,
    .ms-flag-253,
    .ms-flag-263,
    .ms-flag-273,
    .ms-flag-283,
    .ms-flag-293 {
        background-position-x: -200%;
    }
    .ms-flag-gb,
    .ms-flag-uk,
    .ms-flag-tr,
    .ms-flag-ae,
    .ms-flag-th,
    .ms-flag-kr,
    .ms-flag-no,
    .ms-flag-pe,
    .ms-flag-ir,
    .ms-flag-pr,
    .ms-flag-et,
    .ms-flag-ao,
    .ms-flag-bj,
    .ms-flag-ci,
    .ms-flag-eh,
    .ms-flag-gf,
    .ms-flag-gu,
    .ms-flag-kg,
    .ms-flag-lr,
    .ms-flag-mq,
    .ms-flag-nf,
    .ms-flag-pn,
    .ms-flag-sh,
    .ms-flag-td,
    .ms-flag-tz,
    .ms-flag-xk,
    .ms-flag-254,
    .ms-flag-264,
    .ms-flag-274,
    .ms-flag-284,
    .ms-flag-294 {
        background-position-x: -300%;
    }
    .ms-flag-de,
    .ms-flag-br,
    .ms-flag-ar,
    .ms-flag-za,
    .ms-flag-az,
    .ms-flag-ad,
    .ms-flag-kz,
    .ms-flag-ls,
    .ms-flag-cf,
    .ms-flag-ec,
    .ms-flag-aq,
    .ms-flag-bm,
    .ms-flag-ck,
    .ms-flag-er,
    .ms-flag-gh,
    .ms-flag-gw,
    .ms-flag-kh,
    .ms-flag-ly,
    .ms-flag-ms,
    .ms-flag-ng,
    .ms-flag-ps,
    .ms-flag-sj,
    .ms-flag-tf,
    .ms-flag-ug,
    .ms-flag-ye,
    .ms-flag-255,
    .ms-flag-265,
    .ms-flag-275,
    .ms-flag-285,
    .ms-flag-295 {
        background-position-x: -400%;
    }
    .ms-flag-es,
    .ms-flag-cn,
    .ms-flag-zh,
    .ms-flag-hu,
    .ms-flag-mo,
    .ms-flag-pl,
    .ms-flag-sm,
    .ms-flag-co,
    .ms-flag-tw,
    .ms-flag-si,
    .ms-flag-ph,
    .ms-flag-as,
    .ms-flag-bo,
    .ms-flag-cm,
    .ms-flag-eu,
    .ms-flag-gi,
    .ms-flag-gy,
    .ms-flag-ki,
    .ms-flag-md,
    .ms-flag-mt,
    .ms-flag-ni,
    .ms-flag-pw,
    .ms-flag-sl,
    .ms-flag-tg,
    .ms-flag-um,
    .ms-flag-zm,
    .ms-flag-256,
    .ms-flag-266,
    .ms-flag-276,
    .ms-flag-286,
    .ms-flag-296 {
        background-position-x: -500%;
    }
    .ms-flag-fr,
    .ms-flag-in,
    .ms-flag-mc,
    .ms-flag-ma,
    .ms-flag-hk,
    .ms-flag-ee,
    .ms-flag-dk,
    .ms-flag-gl,
    .ms-flag-bb,
    .ms-flag-ve,
    .ms-flag-aw,
    .ms-flag-bs,
    .ms-flag-cr,
    .ms-flag-fj,
    .ms-flag-gk,
    .ms-flag-hm,
    .ms-flag-km,
    .ms-flag-me,
    .ms-flag-mu,
    .ms-flag-np,
    .ms-flag-py,
    .ms-flag-so,
    .ms-flag-tj,
    .ms-flag-uz,
    .ms-flag-zw,
    .ms-flag-257,
    .ms-flag-267,
    .ms-flag-277,
    .ms-flag-287,
    .ms-flag-297 {
        background-position-x: -600%;
    }
    .ms-flag-au,
    .ms-flag-en_au,
    .ms-flag-my,
    .ms-flag-bh,
    .ms-flag-fi,
    .ms-flag-sa,
    .ms-flag-cl,
    .ms-flag-lu,
    .ms-flag-vg,
    .ms-flag-il,
    .ms-flag-af,
    .ms-flag-ax,
    .ms-flag-bt,
    .ms-flag-cu,
    .ms-flag-fk,
    .ms-flag-gm,
    .ms-flag-hn,
    .ms-flag-kn,
    .ms-flag-mg,
    .ms-flag-mv,
    .ms-flag-nr,
    .ms-flag-re,
    .ms-flag-sr,
    .ms-flag-tk,
    .ms-flag-vc,
    .ms-flag-ty,
    .ms-flag-258,
    .ms-flag-268,
    .ms-flag-278,
    .ms-flag-288,
    .ms-flag-298 {
        background-position-x: -700%;
    }
    .ms-flag-jp,
    .ms-flag-ru,
    .ms-flag-cz,
    .ms-flag-uy,
    .ms-flag-ie,
    .ms-flag-ro,
    .ms-flag-dz,
    .ms-flag-ge,
    .ms-flag-tt,
    .ms-flag-ag,
    .ms-flag-ba,
    .ms-flag-bv,
    .ms-flag-cv,
    .ms-flag-fm,
    .ms-flag-gn,
    .ms-flag-ht,
    .ms-flag-kp,
    .ms-flag-mh,
    .ms-flag-mw,
    .ms-flag-nu,
    .ms-flag-rs,
    .ms-flag-st,
    .ms-flag-tm,
    .ms-flag-vi,
    .ms-flag-zr,
    .ms-flag-259,
    .ms-flag-269,
    .ms-flag-279,
    .ms-flag-289,
    .ms-flag-299 {
        background-position-x: -800%;
    }
    .ms-flag-ca,
    .ms-flag-en_ca,
    .ms-flag-at,
    .ms-flag-qa,
    .ms-flag-sg,
    .ms-flag-im,
    .ms-flag-sn,
    .ms-flag-kw,
    .ms-flag-je,
    .ms-flag-va,
    .ms-flag-ai,
    .ms-flag-bd,
    .ms-flag-bw,
    .ms-flag-cx,
    .ms-flag-fo,
    .ms-flag-gp,
    .ms-flag-io,
    .ms-flag-ky,
    .ms-flag-ml,
    .ms-flag-mz,
    .ms-flag-pa,
    .ms-flag-rw,
    .ms-flag-sv,
    .ms-flag-tn,
    .ms-flag-vn,
    .ms-flag-yu,
    .ms-flag-260,
    .ms-flag-270,
    .ms-flag-280,
    .ms-flag-290,
    .ms-flag-300 {
        background-position-x: -900%;
    }
    .ms-flag-be,
    .ms-flag-nl,
    .ms-flag-ch,
    .ms-flag-ch_de,
    .ms-flag-ch_it,
    .ms-flag-ch_fr,
    .ms-flag-tr,
    .ms-flag-br,
    .ms-flag-cn,
    .ms-flag-zh,
    .ms-flag-in,
    .ms-flag-my,
    .ms-flag-ru,
    .ms-flag-at,
    .ms-flag-bf,
    .ms-flag-bg,
    .ms-flag-bi,
    .ms-flag-bj,
    .ms-flag-bm,
    .ms-flag-bo,
    .ms-flag-bs,
    .ms-flag-bt,
    .ms-flag-bv,
    .ms-flag-bw,
    .ms-flag-sb,
    .ms-flag-sc,
    .ms-flag-sd,
    .ms-flag-sh,
    .ms-flag-sj,
    .ms-flag-sl,
    .ms-flag-so,
    .ms-flag-sr,
    .ms-flag-st,
    .ms-flag-sv {
        background-position-y: -100%;
    }
    .ms-flag-pt,
    .ms-flag-mx,
    .ms-flag-lat,
    .ms-flag-ua,
    .ms-flag-ae,
    .ms-flag-ar,
    .ms-flag-hu,
    .ms-flag-mc,
    .ms-flag-bh,
    .ms-flag-cz,
    .ms-flag-qa,
    .ms-flag-bz,
    .ms-flag-cc,
    .ms-flag-cg,
    .ms-flag-ci,
    .ms-flag-ck,
    .ms-flag-cm,
    .ms-flag-cr,
    .ms-flag-cu,
    .ms-flag-cv,
    .ms-flag-cx,
    .ms-flag-sy,
    .ms-flag-sz,
    .ms-flag-tc,
    .ms-flag-td,
    .ms-flag-tf,
    .ms-flag-tg,
    .ms-flag-tj,
    .ms-flag-tk,
    .ms-flag-tm,
    .ms-flag-tn {
        background-position-y: -200%;
    }
    .ms-flag-se,
    .ms-flag-nz,
    .ms-flag-id,
    .ms-flag-th,
    .ms-flag-za,
    .ms-flag-mo,
    .ms-flag-ma,
    .ms-flag-fi,
    .ms-flag-uy,
    .ms-flag-sg,
    .ms-flag-dj,
    .ms-flag-dm,
    .ms-flag-do,
    .ms-flag-eh,
    .ms-flag-er,
    .ms-flag-eu,
    .ms-flag-fj,
    .ms-flag-fk,
    .ms-flag-fm,
    .ms-flag-fo,
    .ms-flag-to,
    .ms-flag-tp,
    .ms-flag-tv,
    .ms-flag-tz,
    .ms-flag-ug,
    .ms-flag-um,
    .ms-flag-uz,
    .ms-flag-vc,
    .ms-flag-vi,
    .ms-flag-vn {
        background-position-y: -300%;
    }
    .ms-flag-sk,
    .ms-flag-lv,
    .ms-flag-gr,
    .ms-flag-kr,
    .ms-flag-az,
    .ms-flag-pl,
    .ms-flag-hk,
    .ms-flag-sa,
    .ms-flag-ie,
    .ms-flag-im,
    .ms-flag-fx,
    .ms-flag-ga,
    .ms-flag-gd,
    .ms-flag-gf,
    .ms-flag-gh,
    .ms-flag-gi,
    .ms-flag-gk,
    .ms-flag-gm,
    .ms-flag-gn,
    .ms-flag-gp,
    .ms-flag-vu,
    .ms-flag-wf,
    .ms-flag-ws,
    .ms-flag-xk,
    .ms-flag-ye,
    .ms-flag-zm,
    .ms-flag-zw,
    .ms-flag-ty,
    .ms-flag-zr,
    .ms-flag-yu {
        background-position-y: -400%;
    }
    .ms-flag-ke,
    .ms-flag-cy,
    .ms-flag-lb,
    .ms-flag-no,
    .ms-flag-ad,
    .ms-flag-sm,
    .ms-flag-ee,
    .ms-flag-cl,
    .ms-flag-ro,
    .ms-flag-sn,
    .ms-flag-gq,
    .ms-flag-gs,
    .ms-flag-gt,
    .ms-flag-gu,
    .ms-flag-gw,
    .ms-flag-gy,
    .ms-flag-hm,
    .ms-flag-hn,
    .ms-flag-ht,
    .ms-flag-io,
    .ms-flag-251,
    .ms-flag-252,
    .ms-flag-253,
    .ms-flag-254,
    .ms-flag-255,
    .ms-flag-256,
    .ms-flag-257,
    .ms-flag-258,
    .ms-flag-259,
    .ms-flag-260 {
        background-position-y: -500%;
    }
    .ms-flag-jo,
    .ms-flag-li,
    .ms-flag-hr,
    .ms-flag-pe,
    .ms-flag-kz,
    .ms-flag-co,
    .ms-flag-dk,
    .ms-flag-lu,
    .ms-flag-dz,
    .ms-flag-kw,
    .ms-flag-iq,
    .ms-flag-is,
    .ms-flag-jm,
    .ms-flag-kg,
    .ms-flag-kh,
    .ms-flag-ki,
    .ms-flag-km,
    .ms-flag-kn,
    .ms-flag-kp,
    .ms-flag-ky,
    .ms-flag-261,
    .ms-flag-262,
    .ms-flag-263,
    .ms-flag-264,
    .ms-flag-265,
    .ms-flag-266,
    .ms-flag-267,
    .ms-flag-268,
    .ms-flag-269,
    .ms-flag-270 {
        background-position-y: -600%;
    }
    .ms-flag-om,
    .ms-flag-mk,
    .ms-flag-lt,
    .ms-flag-ir,
    .ms-flag-ls,
    .ms-flag-tw,
    .ms-flag-gl,
    .ms-flag-vg,
    .ms-flag-ge,
    .ms-flag-je,
    .ms-flag-la,
    .ms-flag-lc,
    .ms-flag-lk,
    .ms-flag-lr,
    .ms-flag-ly,
    .ms-flag-md,
    .ms-flag-me,
    .ms-flag-mg,
    .ms-flag-mh,
    .ms-flag-ml,
    .ms-flag-271,
    .ms-flag-272,
    .ms-flag-273,
    .ms-flag-274,
    .ms-flag-275,
    .ms-flag-276,
    .ms-flag-277,
    .ms-flag-278,
    .ms-flag-279,
    .ms-flag-280 {
        background-position-y: -700%;
    }
    .ms-flag-mr,
    .ms-flag-eg,
    .ms-flag-ds,
    .ms-flag-pr,
    .ms-flag-cf,
    .ms-flag-si,
    .ms-flag-bb,
    .ms-flag-il,
    .ms-flag-tt,
    .ms-flag-va,
    .ms-flag-mm,
    .ms-flag-mn,
    .ms-flag-mp,
    .ms-flag-mq,
    .ms-flag-ms,
    .ms-flag-mt,
    .ms-flag-mu,
    .ms-flag-mv,
    .ms-flag-mw,
    .ms-flag-mz,
    .ms-flag-281,
    .ms-flag-282,
    .ms-flag-283,
    .ms-flag-284,
    .ms-flag-285,
    .ms-flag-286,
    .ms-flag-287,
    .ms-flag-288,
    .ms-flag-289,
    .ms-flag-290 {
        background-position-y: -800%;
    }
    .ms-flag-bn,
    .ms-flag-pf,
    .ms-flag-by,
    .ms-flag-et,
    .ms-flag-ec,
    .ms-flag-ph,
    .ms-flag-ve,
    .ms-flag-af,
    .ms-flag-ag,
    .ms-flag-ai,
    .ms-flag-na,
    .ms-flag-nc,
    .ms-flag-ne,
    .ms-flag-nf,
    .ms-flag-ng,
    .ms-flag-ni,
    .ms-flag-np,
    .ms-flag-nr,
    .ms-flag-nu,
    .ms-flag-pa,
    .ms-flag-291,
    .ms-flag-292,
    .ms-flag-293,
    .ms-flag-294,
    .ms-flag-295,
    .ms-flag-296,
    .ms-flag-297,
    .ms-flag-298,
    .ms-flag-299,
    .ms-flag-300 {
        background-position-y: -900%;
    }
}@media only screen {
    .ms-flag {
        width: 30px;
        height: 18px;
        background-repeat: none;
        background-size: 1000%;
    }
    .ms-flag.ms-flag-global,
    .ms-flag.ms-flag-us,
    .ms-flag.ms-flag-es_us,
    .ms-flag.ms-flag-it,
    .ms-flag.ms-flag-gb,
    .ms-flag.ms-flag-uk,
    .ms-flag.ms-flag-de,
    .ms-flag.ms-flag-es,
    .ms-flag.ms-flag-fr,
    .ms-flag.ms-flag-au,
    .ms-flag.ms-flag-en_au,
    .ms-flag.ms-flag-jp,
    .ms-flag.ms-flag-ca,
    .ms-flag.ms-flag-en_ca,
    .ms-flag.ms-flag-be,
    .ms-flag.ms-flag-nl,
    .ms-flag.ms-flag-ch,
    .ms-flag.ms-flag-ch_de,
    .ms-flag.ms-flag-ch_it,
    .ms-flag.ms-flag-ch_fr,
    .ms-flag.ms-flag-tr,
    .ms-flag.ms-flag-br,
    .ms-flag.ms-flag-cn,
    .ms-flag.ms-flag-zh,
    .ms-flag.ms-flag-in,
    .ms-flag.ms-flag-my,
    .ms-flag.ms-flag-ru,
    .ms-flag.ms-flag-at,
    .ms-flag.ms-flag-pt,
    .ms-flag.ms-flag-mx,
    .ms-flag-s34x20.ms-flag-lat,
    .ms-flag.ms-flag-ua,
    .ms-flag.ms-flag-ae,
    .ms-flag.ms-flag-ar,
    .ms-flag.ms-flag-hu,
    .ms-flag.ms-flag-mc,
    .ms-flag.ms-flag-bh,
    .ms-flag.ms-flag-cz,
    .ms-flag.ms-flag-qa,
    .ms-flag.ms-flag-se,
    .ms-flag.ms-flag-nz,
    .ms-flag.ms-flag-id,
    .ms-flag.ms-flag-th,
    .ms-flag.ms-flag-za,
    .ms-flag.ms-flag-mo,
    .ms-flag.ms-flag-ma,
    .ms-flag.ms-flag-fi,
    .ms-flag.ms-flag-uy,
    .ms-flag.ms-flag-sg,
    .ms-flag.ms-flag-sk,
    .ms-flag.ms-flag-lv,
    .ms-flag.ms-flag-gr,
    .ms-flag.ms-flag-kr,
    .ms-flag.ms-flag-az,
    .ms-flag.ms-flag-pl,
    .ms-flag.ms-flag-hk,
    .ms-flag.ms-flag-sa,
    .ms-flag.ms-flag-ie,
    .ms-flag.ms-flag-im,
    .ms-flag.ms-flag-ke,
    .ms-flag.ms-flag-cy,
    .ms-flag.ms-flag-lb,
    .ms-flag.ms-flag-no,
    .ms-flag.ms-flag-ad,
    .ms-flag.ms-flag-sm,
    .ms-flag.ms-flag-ee,
    .ms-flag.ms-flag-cl,
    .ms-flag.ms-flag-ro,
    .ms-flag.ms-flag-sn,
    .ms-flag.ms-flag-jo,
    .ms-flag.ms-flag-li,
    .ms-flag.ms-flag-hr,
    .ms-flag.ms-flag-pe,
    .ms-flag.ms-flag-kz,
    .ms-flag.ms-flag-co,
    .ms-flag.ms-flag-dk,
    .ms-flag.ms-flag-lu,
    .ms-flag.ms-flag-dz,
    .ms-flag.ms-flag-kw,
    .ms-flag.ms-flag-om,
    .ms-flag.ms-flag-mk,
    .ms-flag.ms-flag-lt,
    .ms-flag.ms-flag-ir,
    .ms-flag.ms-flag-ls,
    .ms-flag.ms-flag-tw,
    .ms-flag.ms-flag-gl,
    .ms-flag.ms-flag-vg,
    .ms-flag.ms-flag-ge,
    .ms-flag.ms-flag-je,
    .ms-flag.ms-flag-mr,
    .ms-flag.ms-flag-eg,
    .ms-flag.ms-flag-ds,
    .ms-flag.ms-flag-pr,
    .ms-flag.ms-flag-cf,
    .ms-flag.ms-flag-si,
    .ms-flag.ms-flag-bb,
    .ms-flag.ms-flag-il,
    .ms-flag.ms-flag-tt,
    .ms-flag.ms-flag-va,
    .ms-flag.ms-flag-bn,
    .ms-flag.ms-flag-pf,
    .ms-flag.ms-flag-by,
    .ms-flag.ms-flag-et,
    .ms-flag.ms-flag-ec,
    .ms-flag.ms-flag-ph,
    .ms-flag.ms-flag-ve,
    .ms-flag.ms-flag-af,
    .ms-flag.ms-flag-ag,
    .ms-flag.ms-flag-ai {
        background-image: url(../../src/css/flags-100x60-1.png);
    }
    .ms-flag.ms-flag-al,
    .ms-flag.ms-flag-am,
    .ms-flag.ms-flag-an,
    .ms-flag.ms-flag-ao,
    .ms-flag.ms-flag-aq,
    .ms-flag.ms-flag-as,
    .ms-flag.ms-flag-aw,
    .ms-flag.ms-flag-ax,
    .ms-flag.ms-flag-ba,
    .ms-flag.ms-flag-bd,
    .ms-flag.ms-flag-bf,
    .ms-flag.ms-flag-bg,
    .ms-flag.ms-flag-bi,
    .ms-flag.ms-flag-bj,
    .ms-flag.ms-flag-bm,
    .ms-flag.ms-flag-bo,
    .ms-flag.ms-flag-bs,
    .ms-flag.ms-flag-bt,
    .ms-flag.ms-flag-bv,
    .ms-flag.ms-flag-bw,
    .ms-flag.ms-flag-bz,
    .ms-flag.ms-flag-cc,
    .ms-flag.ms-flag-cg,
    .ms-flag.ms-flag-ci,
    .ms-flag.ms-flag-ck,
    .ms-flag.ms-flag-cm,
    .ms-flag.ms-flag-cr,
    .ms-flag.ms-flag-cu,
    .ms-flag.ms-flag-cv,
    .ms-flag.ms-flag-cx,
    .ms-flag.ms-flag-dj,
    .ms-flag.ms-flag-dm,
    .ms-flag.ms-flag-do,
    .ms-flag.ms-flag-eh,
    .ms-flag.ms-flag-er,
    .ms-flag.ms-flag-eu,
    .ms-flag.ms-flag-fj,
    .ms-flag.ms-flag-fk,
    .ms-flag.ms-flag-fm,
    .ms-flag.ms-flag-fo,
    .ms-flag.ms-flag-fx,
    .ms-flag.ms-flag-ga,
    .ms-flag.ms-flag-gd,
    .ms-flag.ms-flag-gf,
    .ms-flag.ms-flag-gh,
    .ms-flag.ms-flag-gi,
    .ms-flag.ms-flag-gk,
    .ms-flag.ms-flag-gm,
    .ms-flag.ms-flag-gn,
    .ms-flag.ms-flag-gp,
    .ms-flag.ms-flag-gq,
    .ms-flag.ms-flag-gs,
    .ms-flag.ms-flag-gt,
    .ms-flag.ms-flag-gu,
    .ms-flag.ms-flag-gw,
    .ms-flag.ms-flag-gy,
    .ms-flag.ms-flag-hm,
    .ms-flag.ms-flag-hn,
    .ms-flag.ms-flag-ht,
    .ms-flag.ms-flag-io,
    .ms-flag.ms-flag-iq,
    .ms-flag.ms-flag-is,
    .ms-flag.ms-flag-jm,
    .ms-flag.ms-flag-kg,
    .ms-flag.ms-flag-kh,
    .ms-flag.ms-flag-ki,
    .ms-flag.ms-flag-km,
    .ms-flag.ms-flag-kn,
    .ms-flag.ms-flag-kp,
    .ms-flag.ms-flag-ky,
    .ms-flag.ms-flag-la,
    .ms-flag.ms-flag-lc,
    .ms-flag.ms-flag-lk,
    .ms-flag.ms-flag-lr,
    .ms-flag.ms-flag-ly,
    .ms-flag.ms-flag-md,
    .ms-flag.ms-flag-me,
    .ms-flag.ms-flag-mg,
    .ms-flag.ms-flag-mh,
    .ms-flag.ms-flag-ml,
    .ms-flag.ms-flag-mm,
    .ms-flag.ms-flag-mn,
    .ms-flag.ms-flag-mp,
    .ms-flag.ms-flag-mq,
    .ms-flag.ms-flag-ms,
    .ms-flag.ms-flag-mt,
    .ms-flag.ms-flag-mu,
    .ms-flag.ms-flag-mv,
    .ms-flag.ms-flag-mw,
    .ms-flag.ms-flag-mz,
    .ms-flag.ms-flag-na,
    .ms-flag.ms-flag-nc,
    .ms-flag.ms-flag-ne,
    .ms-flag.ms-flag-nf,
    .ms-flag.ms-flag-ng,
    .ms-flag.ms-flag-ni,
    .ms-flag.ms-flag-np,
    .ms-flag.ms-flag-nr,
    .ms-flag.ms-flag-nu,
    .ms-flag.ms-flag-pa {
        background-image: url(../../src/css/flags-100x60-2.png);
    }
    .ms-flag.ms-flag-pg,
    .ms-flag.ms-flag-pk,
    .ms-flag.ms-flag-pm,
    .ms-flag.ms-flag-pn,
    .ms-flag.ms-flag-ps,
    .ms-flag.ms-flag-pw,
    .ms-flag.ms-flag-py,
    .ms-flag.ms-flag-re,
    .ms-flag.ms-flag-rs,
    .ms-flag.ms-flag-rw,
    .ms-flag.ms-flag-sb,
    .ms-flag.ms-flag-sc,
    .ms-flag.ms-flag-sd,
    .ms-flag.ms-flag-sh,
    .ms-flag.ms-flag-sj,
    .ms-flag.ms-flag-sl,
    .ms-flag.ms-flag-so,
    .ms-flag.ms-flag-sr,
    .ms-flag.ms-flag-st,
    .ms-flag.ms-flag-sv,
    .ms-flag.ms-flag-sy,
    .ms-flag.ms-flag-sz,
    .ms-flag.ms-flag-tc,
    .ms-flag.ms-flag-td,
    .ms-flag.ms-flag-tf,
    .ms-flag.ms-flag-tg,
    .ms-flag.ms-flag-tj,
    .ms-flag.ms-flag-tk,
    .ms-flag.ms-flag-tm,
    .ms-flag.ms-flag-tn,
    .ms-flag.ms-flag-to,
    .ms-flag.ms-flag-tp,
    .ms-flag.ms-flag-tv,
    .ms-flag.ms-flag-tz,
    .ms-flag.ms-flag-ug,
    .ms-flag.ms-flag-um,
    .ms-flag.ms-flag-uz,
    .ms-flag.ms-flag-vc,
    .ms-flag.ms-flag-vi,
    .ms-flag.ms-flag-vn,
    .ms-flag.ms-flag-vu,
    .ms-flag.ms-flag-wf,
    .ms-flag.ms-flag-ws,
    .ms-flag.ms-flag-xk,
    .ms-flag.ms-flag-ye,
    .ms-flag.ms-flag-zm,
    .ms-flag.ms-flag-zw,
    .ms-flag.ms-flag-ty,
    .ms-flag.ms-flag-zr,
    .ms-flag.ms-flag-yu {
        background-image: url(../../src/css/flags-100x60-3.png);
    }
}/*!rtl:end:ignore*/
/*# sourceMappingURL=flags.css.map */